import React, { useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"

import addToMailchimp from "gatsby-plugin-mailchimp"

import { CarouselProvider, Dot, Slider, Slide } from "pure-react-carousel"
import "pure-react-carousel/dist/react-carousel.es.css"

import ReactModal from "react-modal"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Security from "../components/security"

import LogoWall from "../components/LogoWall"

import TargetingAutomation from "../images/landing/targeting.png"
import EngagementAutomation from "../images/landing/engagement.png"
import OptimisationAutomation from "../images/use-cases/revops.png"

import LogoWhiteSVG from "../images/logo-white.svg"

import quoteBg1 from "../images/quote-bg-1-min.png"
import quoteBg2 from "../images/quote-bg-2-min.png"

import customerWorkfront from "../images/customers/Adobe_Workfront_lockup_no_shadow_reversed_0.png"
import customerGoCardless from "../images/customers/GoCardless_Logo_Negative_RGB.png"
import CTA from "../components/cta"
import HeadlessCTA from "../components/headlessCTA"
import { IconArrowRight, IconReplace, IconSpeedboat } from "@tabler/icons"

import IconLayers from "../images/layers.svg"
import IconShoppingBag from "../images/shopping-bag.svg"
import IconTarget from "../images/target.svg"

ReactModal.setAppElement("#___gatsby")

const Quotes = () => {
  const totalSlides = 3
  const slideClasses =
    "md:rounded-xl p-8 md:p-12 text-white flex flex-col items-center mx-4 md:h-80"

  return (
    <section id="customers" className="my-32">
      <div className="lg:text-center mx-auto flex flex-col gap-4 mb-12 container px-4 lg:px-8 text-white">
        <div className="font-bold text-3xl md:text-6xl max-w-3xl lg:mx-auto">
          Learn why revenue teams rely on Tactic
        </div>
        <div className="text-medium-grey text-lg md:text-xl max-w-4xl lg:mx-auto">
          Trusted by hypergrowth startups and Fortune 500
        </div>
      </div>
      <div className="container mx-auto sm:px-6">
        <CarouselProvider
          className="max-w-4xl mx-auto splashCarousel"
          visibleSlides={1}
          totalSlides={totalSlides}
          naturalSlideHeight={200}
          naturalSlideWidth={500}
          isIntrinsicHeight
          isPlaying
          infinite
        >
          <Slider>
            <Slide index={0}>
              <div
                className={slideClasses}
                style={{
                  background: `url("${quoteBg1}") no-repeat top center`,
                  backgroundSize: "100% 100%",
                }}
              >
                <img
                  src={customerGoCardless}
                  className="max-h-12 mb-8"
                  style={{ width: "200px" }}
                />
                <figure className="text-center flex flex-col gap-8 max-w-2xl">
                  <blockquote className="text-2xl font-semibold">
                    <p>
                      &ldquo;With Tactic, we save 10 hours a week per
                      salesperson. Thousands of answers are found, filtered and
                      ranked based on their priorities - so they know where to
                      start.
                    </p>
                  </blockquote>
                  <figcaption className="text-lg font-normal">
                    Jody Clark, <cite>Global VP of Sales Development</cite> at
                    GoCardless
                  </figcaption>
                </figure>
              </div>
            </Slide>
            <Slide index={1}>
              <div
                className={slideClasses}
                style={{
                  background: `url("${quoteBg2}") no-repeat bottom center`,
                  backgroundSize: "100% 100%",
                }}
              >
                <img
                  src={customerWorkfront}
                  className="max-h-12 mb-8"
                  style={{ width: "200px" }}
                />
                <figure className="text-center flex flex-col gap-8 max-w-2xl">
                  <blockquote className="text-2xl font-semibold">
                    <p>
                      &ldquo;Tactic drives our outbound strategy and deep
                      insight into our target accounts, and has been a key piece
                      of technology that helped us scale in the past year.
                    </p>
                  </blockquote>
                  <figcaption className="text-lg font-normal">
                    Andrew Hall, <cite>Director of Field Marketing</cite>, Adobe
                    Workfront
                  </figcaption>
                </figure>
              </div>
            </Slide>

            <Slide index={2}>
              <div
                className={slideClasses}
                style={{
                  background: `url("${quoteBg1}") no-repeat bottom center`,
                  backgroundSize: "100% 100%",
                }}
              >
                <img
                  src={customerWorkfront}
                  className="max-h-12 mb-8"
                  style={{ width: "200px" }}
                />
                <figure className="text-center flex flex-col gap-8 max-w-2xl">
                  <blockquote className="text-2xl font-semibold">
                    <p>
                      &ldquo;With Tactic my research is 5x faster. In a few
                      clicks, I can mine the entire internet for relevant and
                      targeted account actions, combine with my Salesforce data,
                      leading to larger and higher quality pipeline.
                    </p>
                  </blockquote>
                  <figcaption className="text-lg font-normal">
                    George Gilks, <cite>Enterprise Account Executive</cite>,
                    Workfront
                  </figcaption>
                </figure>
              </div>
            </Slide>
          </Slider>
          <div className="flex gap-2 p-8 justify-center">
            {[...Array(totalSlides).keys()].map(idx => (
              <Dot
                className="focus:outline-none h-3 w-3 bg-dark-grey rounded-full"
                slide={idx}
              ></Dot>
            ))}
          </div>
        </CarouselProvider>
      </div>
    </section>
  )
}

const BeforeAfter = () => {
  const teamPillClass =
    "rounded-lg px-3 py-1 text-medium-grey my-3 bg-white/10 border border-medium-grey hover:text-blue hover:border-blue hover:bg-blue/10 transition shadow"

  return (
    <section
      className="container mx-auto flex flex-col bg-gradient-to-b from-[#222326] to-black rounded-xl"
      id="why-tactic"
    >
      <div className="p-8 lg:p-16">
        <div className="max-w-5xl flex flex-col gap-8">
          <div className="text-lilac uppercase tracking-widest">
            What is tactic?
          </div>
          <div className="text-white font-light text-3xl lg:text-6xl">
            Tactic partners with you to build data and insights into your
            biggest competitive advantage.
          </div>
        </div>
        <div className="text-dark-grey mt-12">Tactic for:</div>
        <div className="flex flex-col lg:flex-row lg:gap-2 text-xl">
          <Link className={teamPillClass} to="/solutions#strategy">
            Strategy
          </Link>
          <Link className={teamPillClass} to="/solutions#marketing">
            Marketing
          </Link>
          <Link className={teamPillClass} to="/solutions#sales">
            Sales
          </Link>
          <Link className={teamPillClass} to="/solutions#customer-success">
            Customer Success
          </Link>
          <Link className={teamPillClass} to="/solutions#partnerships">
            Partnerships
          </Link>
          <Link className={teamPillClass} to="/solutions#operations-enablement">
            Operations &amp; Enablement
          </Link>
        </div>
      </div>

      <hr className="border-dark-grey/25" />

      {/* Target Accounts */}
      <section className="text-white my-16 flex flex-col xl:flex-row items-center">
        <div className="flex-1 xl:max-w-3xl px-4 xl:px-16">
          <div className="text-lilac uppercase tracking-widest">
            Targeting automation
          </div>
          <h3 className="text-3xl lg:text-5xl leading-tight mb-4 lg:mb-16 font-semibold">
            Better target accounts
          </h3>
          <div className="text-xl lg:text-2xl text-soft-tones-blue mb-4 flex flex-col gap-4">
            <p>
              Source every account, team, initiative and prospect in your TAM.
              Clean and enrich them with a customised, unlimited selection of
              data.
            </p>
            <p>Score, qualify and segment every company on the planet. </p>
            <p>
              Distribute the right segments to the right channel: inbound,
              outbound, partners, product-led and more.
            </p>
            <div className="text-base flex gap-4 py-4 text-dark-grey">
              <IconReplace /> Replaces self sourcing accounts,
              ZoomInfo/Crunchbase/BVD, data outsourcing, data quality projects.
            </div>
          </div>
        </div>
        <div className="flex-1">
          <img src={TargetingAutomation} className="rounded-l-lg shadow-2xl" />
        </div>
      </section>

      {/* Engagement Insights */}
      <section className="text-white my-16 flex flex-col xl:flex-row items-center gap-4">
        <div className="flex-1">
          <img
            src={EngagementAutomation}
            className="mb-8 xl:mb-0 rounded-r-lg shadow-2xl"
          />
        </div>
        <div className="flex-1 xl:max-w-2xl px-4 xl:px-16">
          <div className="text-lilac uppercase tracking-widest">
            Engagement automation
          </div>
          <h3 className="text-3xl lg:text-5xl leading-tight mb-4 lg:mb-16 font-semibold">
            Personalise every buyer interaction
          </h3>
          <div className="text-xl lg:text-2xl text-soft-tones-blue mb-4 flex flex-col gap-4">
            <p>
              Prioritise accounts based on propensity to buy, ACV, triggers and
              intent.
            </p>
            <p>
              Engage them using detailed insights on goals, pain points, and
              initiatives of each prospect.
            </p>
            <p>
              Close more revenue by building relationships with the entire
              buying committee and connecting your solution to the buyers'
              strategy.
            </p>
            <div className="text-base flex gap-4 py-4 text-dark-grey">
              <IconReplace /> Replaces scraping event lists, media monitoring,
              ABM agencies, manual account plans and maps, territory
              prioritisation spreadsheets.
            </div>
          </div>
        </div>
      </section>

      {/* Target Accounts */}
      <section className="text-white my-16 flex flex-col xl:flex-row items-center">
        <div className="flex-1 xl:max-w-2xl px-4 xl:px-16">
          <div className="text-lilac uppercase tracking-widest">
            Optimisation automation
          </div>
          <h3 className="text-3xl lg:text-5xl leading-tight mb-4 lg:mb-16 font-semibold">
            Apply learnings quickly
          </h3>
          <div className="text-xl lg:text-2xl text-soft-tones-blue mb-4 flex flex-col gap-4">
            <p>
              Replicate high performing reps and campaigns. Find new attributes
              and signals to increase win rates and ACV. Deploy findings into
              your territory plans, marketing strategies and sales tactics.
            </p>
            <div className="text-base flex gap-4 py-4 text-dark-grey">
              <IconSpeedboat /> Accelerates strategic revenue operations
              projects, territory planning and book building.
            </div>
          </div>
        </div>
        <div className="flex-1">
          <img src={OptimisationAutomation} className="xl:pr-16 shadow-2xl" />
        </div>
      </section>
    </section>
  )
}

const WhatIsTactic = () => {
  return (
    <section className="py-12 md:py-24 text-white bg-lilac-15">
      <div className="lg:text-center mx-auto flex flex-col gap-4 mb-12 container px-4 lg:px-8">
        <div className="font-bold text-3xl md:text-6xl max-w-3xl lg:mx-auto">
          Understand every account in detail
        </div>
        <div className="text-medium-grey text-lg md:text-xl max-w-4xl mx-auto">
          Customisable data helps you capture granular account and prospect
          attributes to better target your sales, marketing and customer
          success. Here is how it works.
        </div>
      </div>

      <div className="flex flex-col lg:flex-row gap-3 container mx-auto mt-24 mb-8 items-center">
        <div className="h-px bg-white/10 lg:flex-1"></div>
        <div className="font-bold text-2xl md:text-3xl text-lilac uppercase">
          Customisable data
        </div>
        <div className="h-px bg-white/10 lg:flex-1"></div>
      </div>

      <div className="flex flex-col lg:flex-row container mx-auto gap-6 xl:gap-12">
        <Link
          to="/product#targeting"
          className="flex-1 flex flex-col gap-8 p-8 md:p-12 bg-gradient-to-b 
          from-gray-900 hover:from-gray-800 transition rounded-lg items-start"
        >
          <div className="bg-gray-900 border border-gray-500 rounded-lg p-3">
            <img src={IconTarget} />
          </div>
          <div className="text-lg md:text-2xl">Better target accounts</div>
          <div className="text-white/50 lg:mb-24">
            Track the right metrics across your target market to prioritise
            better.
          </div>
          <IconArrowRight className="border-2 rounded-full w-7 h-7 text-lilac border-lilac" />
        </Link>

        <Link
          to="/product#personalisation"
          className="flex-1 flex flex-col gap-8 p-8 md:p-12 bg-gradient-to-b 
          from-gray-900 hover:from-gray-800 transition rounded-lg items-start"
        >
          <div className="bg-gray-900 border border-gray-500 rounded-lg p-3">
            <img src={IconShoppingBag} />
          </div>
          <div className="text-lg md:text-2xl">
            Personalise every interaction
          </div>
          <div className="text-white/50 lg:mb-24">
            Surface quotes, blogs, and interviews from decision makers to find
            triggers faster.
          </div>
          <IconArrowRight className="border-2 rounded-full w-7 h-7 text-lilac border-lilac" />
        </Link>

        <Link
          to="/product#iteration"
          className="flex-1 flex flex-col gap-8 p-8 md:p-12 bg-gradient-to-b 
          from-gray-900 hover:from-gray-800  rounded-lg items-start"
        >
          <div className="bg-gray-900 border border-gray-500 rounded-lg p-3">
            <img src={IconLayers} />
          </div>
          <div className="text-lg md:text-2xl">Apply learnings faster</div>
          <div className="text-white/50 lg:mb-24">
            When your ideal customer changes, your data changes with it.
          </div>
          <IconArrowRight className="border-2 rounded-full w-7 h-7 text-lilac border-lilac" />
        </Link>
      </div>

      <div className="container mx-auto text-center">
        <Link className="text-blue" to="/product">
          Product Overview &rarr;
        </Link>
      </div>
    </section>
  )
}

const Blurb = () => {
  return (
    <section className="container mx-auto flex flex-col px-4 lg:px-8 py-24">
      <div className="max-w-4xl flex flex-col gap-8">
        <div className="text-white font-light text-3xl lg:text-5xl">
          Give every team the right data to scale revenue efficiently
        </div>
        <div className="text-dark-grey lg:text-lg">
          Automate audience, prospect and customer research across Google,
          Linkedin, job posts, annual reports and more. Deliver your team higher
          fit target accounts, a wider range of intent triggers, and deeper
          insights. Close more deals with less people.
        </div>
        <div className="text-lilac font-light text-2xl">
          The results speak for themselves
        </div>
        <div className="flex gap-12 my-12">
          <div className="text-soft-tones-lime">
            <div className="text-3xl lg:text-6xl">+40%</div>
            <div>pipeline efficiency</div>
          </div>
          <div className="text-soft-tones-blue">
            <div className="text-3xl lg:text-6xl">+79%</div>
            <div>deal size</div>
          </div>
          <div className="text-soft-tones-amber">
            <div className="text-3xl lg:text-6xl">10+ hrs</div>
            <div>saved per rep</div>
          </div>
        </div>
        <Link className="text-blue" to="/gocardless">
          Read customer stories here &rarr;
        </Link>
      </div>
    </section>
  )
}

const RedStepDot = ({ step }) => (
  <span className="h-10 w-10 bg-red-500 font-bold py-2 text-center rounded-full block">
    {step}
  </span>
)

RedStepDot.propTypes = {
  step: PropTypes.number,
}

const IndexPage = () => {
  const [isModalOpen, setModalOpen] = useState(false)
  const [email, setEmail] = useState("")
  const [isSuccess, setIsSuccess] = useState(false)

  const handleSubmit = e => {
    e.preventDefault()
    addToMailchimp(email, {
      FULLNAME: "Tactic Landing Page",
      NOTES: `Request Demo`,
      FUNCTION: "Sales, Marketing, or Growth",
    })
      .then(data => {
        if (data.result === "error") {
          alert(data.msg)
        }
        if (data.result === "success") {
          setIsSuccess(true)
        }
      })
      .catch(error => {
        console.log(error)
      })
  }

  return (
    <Layout variant="plain" showNav={false}>
      <SEO
        title="GTM Data Automation Platform"
        description="Tactic enables B2B companies automate manual workflows around data, insights and research, increasing revenue efficiency by 40%."
      />

      <ReactModal
        isOpen={isModalOpen}
        onRequestClose={() => setModalOpen(false)}
        contentLabel="QuantCopy Tour Video"
        style={{
          content: {
            width: 1280,
            height: 720,
            top: "calc(50% - 360px)",
            left: "calc(50% - 640px)",
            padding: 0,
            zIndex: 50,
          },
          overlay: {
            zIndex: 40,
            backgroundColor: "rgba(0, 0, 0, 0.75)",
          },
        }}
      >
        <iframe
          id="ytplayer"
          type="text/html"
          width="100%"
          height="100%"
          src="https://www.youtube.com/embed/vYul1qxS6zg?autoplay=1&origin=https://tactic.fyi&modestbranding=1&rel=0&cc_load_policy=1"
          frameBorder="0"
        ></iframe>
      </ReactModal>

      <div className="container mx-auto flex flex-col xl:flex-row gap-12 items-center py-16 md:py-24 pl-0 md:pl-8">
        <div
          className="flex flex-col gap-8 px-6 md:px-0 max-w-3xl"
          id="hero-container"
        >
          <div>
            <img src={LogoWhiteSVG} className="h-20" />
          </div>
          <h1
            className="font-medium bg-clip-text text-transparent bg-gradient-to-br from-lilac to-blue text-5xl md:text-7xl tracking-tight"
            style={{
              fontFeatureSettings: "'ss02' on, 'ss03' on, 'ss01' on",
            }}
          >
            Achieve your revenue goals with Tactic
          </h1>
          <h2
            className="text-medium-grey max-w-2xl text-xl"
            style={{
              fontFeatureSettings: "'ss02' on, 'ss03' on, 'ss01' on",
            }}
          >
            Boost win rates, increase average deal size, and better ICPs with
            Tactic, the #1 customisable data platform. Book a demo now and learn
            how startups and Fortune 500 alike use Tactic to drive profitable
            growth.
          </h2>
        </div>
        <div className="sm:rounded-lg bg-lilac/75 p-4 md:p-12 w-full min-h-[300px] flex flex-col justify-center items-center">
          <div className="text-white text-center text-2xl mb-6">
            Get your personalised demo
          </div>
          <HeadlessCTA />
        </div>
      </div>
      <div className="flex flex-col items-center">
        <div className="text-white/50 text-center">
          You are in great company
        </div>
        <LogoWall />
      </div>
      <Blurb />
      <WhatIsTactic />
      <Quotes />
      <CTA />
      <Security />
    </Layout>
  )
}

export default IndexPage
